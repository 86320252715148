.loader {
  border: 8px solid hwb(0 95% 5%);
  border-top: 10px solid theme("colors.border-strong-2");
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
